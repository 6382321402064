import React, { useState, useEffect } from 'react';
import Icons from '../../../../../Common/IconsComponent';
import { usePopup } from '../../../../../Popups/PopupContext';
import { useGetEventsMutation } from '../../../../../../services/phpService';
import './EventStats.scss';

const EventStats = ({ eventId }) => {
	const { user, hidePopup } = usePopup();
	const [getEvents] = useGetEventsMutation();
	const [eventsData, setEventsData] = useState([]);
	const [userEventData, setUserEventData] = useState(null);
	const [currentEvent, setCurrentEvent] = useState(null);

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const res = await getEvents().unwrap();
				setEventsData(res);
			} catch (error) {
				console.error('Something went wrong:', error);
			}
		};
		fetchEvents();
	}, []);

	useEffect(() => {
		if (user?.events_gaming && eventId) {
			const foundEventData = user.events_gaming.find((game) => game.event_id === eventId);
			setUserEventData(foundEventData);
		}
	}, [user, eventId]);

	useEffect(() => {
		const foundEvent = eventsData.find((event) => event.id === eventId);
		setCurrentEvent(foundEvent);
	}, [eventsData, eventId]);

	const closeModal = () => {
		hidePopup();
	};

	const team1Votes = currentEvent?.gaming?.[0]?.total_votes ?? 'N/A';
	const team2Votes = currentEvent?.gaming?.[1]?.total_votes ?? 'N/A';

	const userTeamId = userEventData?.event_gaming_id;
	const isUserTeam1 = currentEvent?.gaming?.[0]?.id === userTeamId;
	const isUserTeam2 = currentEvent?.gaming?.[1]?.id === userTeamId;

	const taskCount =
		user?.events_tasks?.filter((task) => task.event_id === eventId)?.length ?? 0;

	return (
		<div className='eventStats'>
			<div className='eventStats__header'>
				<div className='eventStats__close' onClick={closeModal}>
					<Icons.Close />
				</div>
				<div className='eventStats__yourTaps'>
					<h4>Your Taps</h4>
					<span>{userEventData?.taps ?? 'N/A'}</span>
				</div>
			</div>
			<div className='eventStats__eventInfo'>
				<div className='eventStats__info'>
					<Icons.EventInfoShit />
					<span>Total Shit Bank</span>
					<span>{Math.round(currentEvent?.shit_bank ?? 0)}</span>
				</div>
				<div className='eventStats__info'>
					<Icons.EventInfoStar />
					<span>Total Stars Bank</span>
					<span>{Math.round(currentEvent?.stars_bank ?? 0)}</span>
				</div>
				<div className='eventStats__info'>
					<Icons.EventInfoSiluet />
					<span>Total Players</span>
					<span>{currentEvent?.total_joined ?? 'N/A'}</span>
				</div>
				<div className='eventStats__info'>
					{isUserTeam1 ? <Icons.EventInfoPlayerYellow /> : <Icons.EventInfoPlayer />}
					<span style={{ color: isUserTeam1 ? 'yellow' : 'inherit' }}>Team 1</span>
					<span style={{ color: isUserTeam1 ? 'yellow' : 'inherit' }}>{team1Votes}</span>
				</div>
				<div className='eventStats__info'>
					{isUserTeam2 ? <Icons.EventInfoPlayerYellow /> : <Icons.EventInfoPlayer />}
					<span style={{ color: isUserTeam2 ? 'yellow' : 'inherit' }}>Team 2</span>
					<span style={{ color: isUserTeam2 ? 'yellow' : 'inherit' }}>{team2Votes}</span>
				</div>
				<div className='eventStats__info'>
					<Icons.EventInfoTasks />
					<span>Tasks Completed</span>
					<span>{taskCount}</span>
				</div>
			</div>
		</div>
	);
};

export default EventStats;
