import React, { useState, useEffect, useRef } from 'react';
import Icons from '../../../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import { useGetEventLeadersMutation } from '../../../../../../services/phpService.js';
import MyTitle from '../../../../../Common/styles/MyTitle.jsx';
import './EventLeaders.scss';

const EventLeaders = ({ eventId }) => {
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [getEventLeaders] = useGetEventLeadersMutation();
	const { user, hidePopup } = usePopup();

	const id_telegram = user?.id_telegram;
	const id_event_gaming = eventId;

	useEffect(() => {
		if (user && eventId) {
			fetchData();
		}
	}, [user, eventId]);

	const fetchData = async () => {
		try {
			const res = await getEventLeaders({ id_event_gaming, id_telegram });

			if (res?.data) {
				setLeaderboardData(res.data);
			}
		} catch (error) {
			console.error('Error fetching leaderboard data:', error);
		}
	};

	const closeModal = () => {
		hidePopup();
	};

	return (
		<div className='ratings' style={{ height: '100%' }}>
			<div
				className='ratings__container container'
				style={{
					height: '100%',
					background: '#fff',
					borderTopLeftRadius: '50%',
					borderTopRightRadius: '50%',
				}}
			>
				<div className='ratings__body' style={{ height: '100%' }}>
					<div className='ratings__close' onClick={closeModal}>
						<Icons.Close />
					</div>
					<MyTitle>Leaderboard</MyTitle>
					<ul className='ratings__list'>
						{leaderboardData
							.filter((player) => player.total_votes)
							.map((player, index) => {
								const isCurrentUser = player.username === user?.username;
								const isTopThree = index < 3;
								return (
									<li
										className={`ratings__listItem ${
											isCurrentUser && !isTopThree ? 'highlight' : ''
										}`}
										key={index}
									>
										<div className='ratings__itemData'>
											<div className='ratings__listId'>
												<Icons.PlayerId />
												<span>{player.position}</span>
											</div>
											<div className='ratings__listName'>
												<span>
													{player.username
														? player.username.length > 10
															? `${player.username.substring(0, 10)}...`
															: player.username
														: `${player.first_name ?? 'Anonymous'} ${
																player.last_name ?? ''
														  }`}
												</span>
											</div>
											<div className='ratings__listBalance'>
												<span>{player.total_votes}</span>
											</div>
										</div>
									</li>
								);
							})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default EventLeaders;
