import React from 'react';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import Icons from '../../../../../Common/IconsComponent.jsx';
import EventTasksItem from './EventTasksItem.jsx';

const EventTasks = ({ tasks, eventId }) => {
	const { hidePopup } = usePopup();

	const closeModal = () => {
		hidePopup();
	};

	const getLiClassName = (index, status) => {
		let classname = '';
		if (index >= 5) {
			classname = status ? 'hidden' : 'waiting';
		}
		return 'tasks__item ' + classname;
	};

	// Filter tasks based on events_gaming_id and eventId
	const filteredTasks = tasks.filter(
		(task) => task.events_gaming_id === 0 || task.events_gaming_id === eventId
	);

	return (
		<div className='main__tasks tasks'>
			<div className='modal__close' onClick={closeModal}>
				<Icons.Close />
			</div>

			{filteredTasks.length > 0 ? (
				<ul className='tasks__list'>
					{filteredTasks.map((task, index) => (
						<li key={task.id} className={getLiClassName(index, task.status)}>
							<EventTasksItem task={task} eventId={eventId} />
						</li>
					))}
				</ul>
			) : (
				<div className='tasks__empty'>No tasks available.</div>
			)}
		</div>
	);
};

export default EventTasks;
