import React from 'react';
import QRimg from '../../../assets/img/qr.webp';
import shitLogo from '../../../assets/img/main/image.png';
import './QRscreen.scss'; // Import the CSS file for styling

const QRscreen = () => {
	return (
		<div
			className='qrComponent'
		>
			<div className='qrComponent__img'
			style={{
				height: 'auto',
				width: '100%',
				display: 'block',
				textAlign: 'center',
				marginTop: '50px'
			}}
			>
				<img style={{
					maxWidth:'140px',
					maxHeight:'60px',
				}}
					src={shitLogo}
					alt='shit logo'
				/>
			</div>
			<div className='qrComponent__text'>
				<h4>
					{' '}
					Leave the desktop
					<br />
					Mobile is the $hit!
				</h4>
			</div>
			<div className='qrComponent__img'
			style={{marginTop: '15px'}}
			>
				<img
					src={QRimg}
					alt='QR redirect'
				/>
			</div>
		</div>
	);
};

export default QRscreen;
