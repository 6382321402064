import React, { useState, useEffect, useRef } from 'react';
import Icons from '../../../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import { usePassEventTaskMutation } from '../../../../../../services/phpService.js';
import { animateDoneTask } from '../../../../../../helpers/tasksAnimationHelper.js';

const EventTasksItem = ({ task, eventId }) => {
	const { user, setUser, genHash, addNotification } = usePopup();

	const [isClicked, setIsClicked] = useState(false);
	const [isCheckStage, setIsCheckStage] = useState(false);
	const [remainingTime, setRemainingTime] = useState(10);
	const [timerStarted, setTimerStarted] = useState(false);
	const [timerFinished, setTimerFinished] = useState(false);
	const [taskStatusDone, setTaskStatusDone] = useState(false);
	const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [passEventTask] = usePassEventTaskMutation();
	const taskElement = useRef(null);

	useEffect(() => {
		const taskExists = user?.events_tasks?.some((eventTask) => {
			return Number(eventTask.events_task_id) === Number(task.id);
		});

		if (taskExists) {
			setTaskStatusDone(true);
		}
	}, [user, task.id]);

	useEffect(() => {
		let interval;
		if (timerStarted && !timerFinished) {
			interval = setInterval(() => {
				setRemainingTime((prev) => {
					if (prev > 1) {
						return prev - 1;
					} else {
						clearInterval(interval);
						setTimerFinished(true);
						return 0;
					}
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [timerStarted, timerFinished]);

	const openLink = (link) => {
		if (!taskStatusDone) {
			setIsClicked(true);
			setIsCheckStage(true);
			setShowConfirmationMessage(true);
		}
		window.open(link, '_blank');
	};

	const startTimer = (event) => {
		event.stopPropagation();
		setTimerStarted(true);
	};

	const checkTask = async (event) => {
		event.stopPropagation();

		if (isButtonDisabled || !timerFinished) {
			console.warn('Attempt to check task before timer finished or button is disabled.');
			if (!timerFinished) {
				addNotification('error', 'Task is not completed. Please wait for the timer.');
			}
			return;
		}

		setIsButtonDisabled(true);

		try {
			const res = await passEventTask({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				events_task_id: task.id,
			}).unwrap();

			if (!res.events_tasks) {
				console.error('No tasks returned in response. Possible 404 or server error.');
				addNotification('error', 'Tasks not found. Please try again.');
				return;
			}

			const taskExistsInUpdatedList = res.events_tasks.some((eventTask) => {
				return Number(eventTask.events_task_id) === Number(task.id);
			});

			if (taskExistsInUpdatedList) {
				setTaskStatusDone(true);
				setIsClicked(false);
				setShowConfirmationMessage(false);
				animateDoneTask(taskElement);

				const updatedUser = {
					...user,
					events_tasks: res.events_tasks,
					wallet_balance: res.wallet_balance,
				};

				setUser(updatedUser);

				addNotification('success', 'Great job! The task is done!');
			} else {
				console.warn(`Task with ID ${task.id} not found in the updated list.`);
				addNotification('error', 'Task check failed. Please try again.');
			}
		} catch (error) {
			console.error('Error during task check:', error);
			if (error?.status === 404) {
				addNotification('error', 'Task not found. Please try again later.');
			} else {
				addNotification('error', 'An unexpected error occurred. Please try again.');
			}
		}
	};

	return (
		<a
			href='#'
			className='tasks__link'
			onClick={() => openLink(task?.link)}
			ref={taskElement}
		>
			<div className='tasks__name'>
				{task?.name}
				{!taskStatusDone && showConfirmationMessage && (
					<div style={{ fontFamily: 'Kumbh', color: '#fff500', marginTop: '5px' }}>
						Are you certain you complete this task?
					</div>
				)}
			</div>

			{taskStatusDone ? (
				<div className='tasks__value tasks__value_done'>done</div>
			) : isClicked ? (
				<div className='tasks__value tasks__value_check'>
					{isCheckStage ? (
						timerStarted ? (
							timerFinished ? (
								<button onClick={checkTask} disabled={isButtonDisabled}>
									Claim
								</button>
							) : (
								<span onClick={(e) => e.stopPropagation()}>
									{remainingTime}&nbsp;
									<span style={{ fontSize: '14px' }}>s</span>
								</span>
							)
						) : (
							<button onClick={startTimer}>Check</button>
						)
					) : null}
				</div>
			) : (
				<div onClick={() => openLink(task?.link)} className='tasks__value'>
					+{task?.reward} <Icons.ShitIcon />
				</div>
			)}
		</a>
	);
};

export default EventTasksItem;
