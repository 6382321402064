import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import NavigationWidget from '../../../NavigationWidget';
import {
	useGetEventsMutation,
	useGetEventStatusMutation,
} from '../../../../../../services/phpService';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import Preloader from '../../../Preloader/Preloader';
import EventLeaders from '../Event/EventLeaders';
import { useEventWinnerPopup } from '../../../../../../helpers/eventNotify';
import EventSlide from './EventSlide';

const EventScreen = () => {
	const { user, showPopup } = usePopup();
	useEventWinnerPopup(user, showPopup);
	const [getEvents] = useGetEventsMutation();
	const [getEventStatus] = useGetEventStatusMutation();
	const [eventsList, setEventsList] = useState([]);
	const [currentEvent, setCurrentEvent] = useState(null);
	const navigate = useNavigate();
	const { eventId } = useParams();
	const [loading, setLoading] = useState(true);

	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	const fetchData = async () => {
		setLoading(true);
		try {
			await getEventStatus();
			const eventsRes = await getEvents().unwrap();
			const updatedEvents = eventsRes.map((event) => ({
				...event,
				status: calculateEventStatus(event),
			}));
			setEventsList(updatedEvents);
			const matchedEvent = updatedEvents.find((event) => event.id === parseInt(eventId));
			setCurrentEvent(matchedEvent || updatedEvents[0]);
		} catch (error) {
			console.error('Something went wrong:', error);
		} finally {
			setTimeout(() => setLoading(false), 1000);
		}
	};

	useEffect(() => {
		fetchData();
	}, [getEvents, getEventStatus]);

	useEffect(() => {
		const timer = setInterval(() => {
			setEventsList((prevEvents) =>
				prevEvents.map((event) => ({
					...event,
					status: calculateEventStatus(event),
				}))
			);
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	const calculateEventStatus = (event) => {
		const currentTime = moment().tz('UTC').unix();
		if (currentTime === event.end_time) {
			fetchData();
		}
		if (currentTime < event.start_time) {
			return 0;
		} else if (currentTime >= event.start_time && currentTime < event.end_time) {
			return 1;
		} else {
			return 2;
		}
	};

	const hasJoinedEvent = (gamingIds) => {
		if (!user || !user.events_gaming) {
			return false;
		}
		const joinedEvent = user.events_gaming.find((event) =>
			gamingIds.includes(event.event_gaming_id)
		);
		return joinedEvent ? joinedEvent.event_gaming_id : null;
	};

	const navBack = () => {
		navigate(`/activity`, { replace: true });
	};

	const startEvent = (
		gamingId,
		payValueShit,
		payValueStars,
		eventId,
		eventStatus,
		image
	) => {
		const eventData = {
			gamingId,
			payValueShit,
			payValueStars,
			eventId,
			eventStatus,
			image,
		};
		localStorage.setItem('eventData', JSON.stringify(eventData));
		navigate(`/startEvent`, {
			state: eventData,
		});
	};

	const continueEvent = (gamingId) => {
		if (gamingId) {
			navigate(`/events/${gamingId}/game`);
		} else {
			console.log('User has not joined any event.');
		}
	};

	const formatNumber = (num) => {
		if (num >= 1000000) {
			return (Math.round((num / 1000000) * 10) / 10) % 1 === 0
				? `${Math.floor(num / 1000000)}M`
				: `${(num / 1000000).toFixed(1)}M`;
		} else if (num >= 1000) {
			return (Math.round((num / 1000) * 10) / 10) % 1 === 0
				? `${Math.floor(num / 1000)}k`
				: `${(num / 1000).toFixed(1)}k`;
		} else {
			return num.toString();
		}
	};

	const handleShowLeaders = (eventGamingId) => {
		showPopup(<EventLeaders eventId={eventGamingId} />);
	};

	return (
		<>
			{loading || eventsList.length === 0 ? (
				<Preloader />
			) : (
				<div className='projects'>
					<div className='projects__body'>
						<div className='projects__slider'>
							<Swiper
								modules={[Navigation]}
								spaceBetween={50}
								slidesPerView={1}
								loop={true}
							>
								{eventsList.map((event) => (
									<SwiperSlide key={event.id}>
										<EventSlide
											event={event}
											user={user}
											imageBaseUrl={imageBaseUrl}
											formatNumber={formatNumber}
											navBack={navBack}
											startEvent={startEvent}
											continueEvent={continueEvent}
											handleShowLeaders={handleShowLeaders}
											hasJoinedEvent={hasJoinedEvent}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
};

export default EventScreen;
