import React, { useEffect, useRef } from 'react';
import EventWinner from '../components/Pages/Home/Projects/Project/Popup/EventWinner';

export const useEventWinnerPopup = (user, showPopup) => {
  const shownRef = useRef(false);

  useEffect(() => {
    if (!user?.events_gaming || user.events_gaming.length === 0 || shownRef.current) return;

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    if (isMobileDevice) {
      const qualifyingEvent = user.events_gaming.find(
        (event) => event.winner_notify === 0 && event.completed === 1
      );

      if (qualifyingEvent) {
        const isWinner = qualifyingEvent.winner === 1;

        setTimeout(() => {
          if (!shownRef.current) {
            showPopup(<EventWinner winner={isWinner} eventId={qualifyingEvent.id} />);
            shownRef.current = true; 
          }
        }, 5000);
      }
    }
  }, [user]);
};