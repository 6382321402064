import React, { useState, useEffect, useRef, useCallback } from 'react';
import Icons from '../../../../../Common/IconsComponent';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopup } from '../../../../../Popups/PopupContext';
import {
	useUpdateEventBalanceMutation,
	useGetEventsMutation,
	useGetEventStatusMutation,
} from '../../../../../../services/phpService';
import { useNavigate, useParams } from 'react-router-dom';
import harrisHero from '../../../../../../assets/animations/harris.riv';
import trumpHero from '../../../../../../assets/animations/trump.riv';
import shitDefault from '../../../../../../assets/animations/potato.riv';
import debounce from 'lodash.debounce';
import useBoost from '../../../../../../helpers/clickerBoostHelper';
import moment from 'moment-timezone';
import boostIcon from '../../../../../../assets/img/shitBoost.webp';
import EventTasks from './EventTasks';
import EventLeaders from './EventLeaders';
import EventStats from './EventStats';
import Preloader from '../../../Preloader/Preloader';
import { MyButton, MyBalance } from '../../../../../Common/styles';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import eventFlag from '../../../../../../assets/img/flagImg.webp';
import { useEventWinnerPopup } from '../../../../../../helpers/eventNotify';
import './Event.scss';

export default function Event() {
	const { genHash, addNotification, user, setUser, showPopup } = usePopup();
	useEventWinnerPopup(user, showPopup);
	const [updateEventBalance] = useUpdateEventBalanceMutation();
	const [getEvents] = useGetEventsMutation();
	const [getEventStatus] = useGetEventStatusMutation();
	const [currCoins, setCurrCoins] = useState(0);
	const [gamePaused, setGamePaused] = useState(false);
	const [timeRemaining, setTimeRemaining] = useState(null);
	const [canPlayAt, setCanPlayAt] = useState(10);
	const [isAnimationActive, setIsAnimationActive] = useState(false);
	const [animations, setAnimations] = useState([]);
	const accumulatedCoinsRef = useRef(0);
	const [activeTasksCount, setActiveTasksCount] = useState(0);
	const [matchingTasks, setMatchingTasks] = useState([]);
	const [tasksReady, setTasksReady] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [energyVal, setEnergyVal] = useState(user?.tap_value ?? 1);
	const [clickNewCoins, setClickNewCoins] = useState(user?.tap_value ?? 1);
	const [unsubmittedCoins, setUnsubmittedCoins] = useState(0);
	const { visible, position, activateBoost } = useBoost(gamePaused);

	const { eventSideId } = useParams();
	const [currentEvent, setCurrentEvent] = useState(null);
	const [chosenSideData, setChosenSideData] = useState(null);

	const navigate = useNavigate();
	const secretURL = process.env.REACT_APP_SECRET_URL;
	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	const parsedEventSideId = parseInt(eventSideId, 10);
	const foundEvent = user?.events_gaming?.find(
		(game) => game.event_gaming_id === parsedEventSideId
	);
	const eventId = foundEvent?.event_id;
	const eventGamingId = foundEvent?.event_gaming_id;

	const fetchData = async () => {
		setIsLoading(true);
		try {
			await getEventStatus();
			const eventsRes = await getEvents().unwrap();

			const parsedEventSideId = parseInt(eventSideId, 10);

			const updatedEvents = eventsRes.map((event) => ({
				...event,
				status: calculateEventStatus(event),
			}));

			const matchedEvent = updatedEvents.find((event) =>
				event.gaming.some((game) => game.id === parsedEventSideId)
			);

			setCurrentEvent(matchedEvent);
		} catch (error) {
			console.error('Something went wrong:', error);
		}
	};

	useEffect(() => {
		if (user && foundEvent) {
			if (foundEvent) {
				setChosenSideData({
					event_gaming_id: foundEvent.event_gaming_id,
					votes: foundEvent.votes || 0,
					energy: foundEvent.energy || 0,
					can_play_at: foundEvent.can_play_at,
					sessions: foundEvent.sessions || 0,
					current_session: foundEvent.current_session || 1,
					taps: foundEvent.taps || 0,
					event_id: foundEvent.event_id,
					status: foundEvent.completed,
				});
			}
		} else {
			navigate('/');
		}
	}, [user, foundEvent]);

	useEffect(() => {
		fetchData();
	}, [getEvents, getEventStatus]);

	useEffect(() => {
		setTimeout(() => {
			if (foundEvent) {
				setCurrCoins(foundEvent.energy);
				setCanPlayAt(foundEvent.can_play_at);
			}
		}, 500);
	}, []);

	useEffect(() => {
		if (user && chosenSideData) {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 1500);

			return () => clearTimeout(timer);
		}
	}, [user, chosenSideData]);

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentEvent((prevEvent) => {
				if (!prevEvent) return null;

				return {
					...prevEvent,
					status: calculateEventStatus(prevEvent),
				};
			});
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	const calculateEventStatus = (event) => {
		const currentTime = moment().tz('UTC').unix();
		if (currentTime === event.end_time) {
			fetchData();
			setTimeout(() => {
				navigate(`/events/${eventId}`);
			}, 1000);
		}
	};

	useEffect(() => {
		if (!currentEvent) return;

		if (currentEvent.active_tasks && currentEvent.gaming) {
			const eventGamingId = currentEvent.gaming.find(
				(game) => game.id === parseInt(eventSideId, 10)
			)?.id;

			if (eventGamingId) {
				const matchingTasks = currentEvent.active_tasks.filter(
					(task) => task.events_gaming_id === eventGamingId || task.events_gaming_id === 0
				);

				setMatchingTasks(matchingTasks);
				setActiveTasksCount(matchingTasks.length);
				setTasksReady(true);
			}
		}
	}, [currentEvent, eventSideId]);

	const navBack = useCallback(() => {
		if (eventId) {
			navigate(`/events/${eventId}`);
		}
	}, [navigate, eventId]);

	const getImageSource = (gamingId) => {
		if (!currentEvent || !currentEvent.gaming) return shitDefault;

		if (gamingId === 1) return trumpHero;
		if (gamingId === 2) return harrisHero;

		const matchingSide = currentEvent.gaming.find((game) => game.id === gamingId);
		return matchingSide?.image ? `${imageBaseUrl}/${matchingSide.image}` : shitDefault;
	};

	const animationSrc = getImageSource(chosenSideData?.event_gaming_id);
	const isRivFile = animationSrc.endsWith('.riv');

	const { rive, RiveComponent } = useRive(
		{
			src: animationSrc,
			stateMachines: 'State Machine 1',
			autoplay: true,
		},
		{ enabled: isRivFile }
	);

	const EventHeroComponent = isRivFile ? (
		<RiveComponent style={{ scale: '1', zIndex: 10 }} />
	) : (
		<img
			src={animationSrc}
			alt='Event Hero'
			style={{ scale: '0.7', zIndex: 10, height: '100%', borderRadius: '50%' }}
		/>
	);

	const tapInput = useStateMachineInput(rive, 'State Machine 1', 'tap');

	useEffect(() => {
		const currentTimeStamp = moment.tz('UTC').unix();
		const remainingTime = canPlayAt - currentTimeStamp;

		if (remainingTime <= 0) {
			setGamePaused(false);
			setTimeRemaining(null);
			return;
		}

		const updateGameStatus = () => {
			const currentTimeStamp = moment.tz('UTC').unix();
			const remainingTime = canPlayAt - currentTimeStamp;

			if (remainingTime <= 0) {
				setGamePaused(false);
				setTimeRemaining(null);
				clearInterval(timer);
			} else {
				setGamePaused(true);
				setTimeRemaining(remainingTime);
			}
		};

		const timer = setInterval(updateGameStatus, 1000);

		return () => clearInterval(timer);
	}, [canPlayAt]);

	useEffect(() => {
		let pauseTimeoutId;
		if (currCoins >= (user?.max_energy ?? 1000)) {
			setGamePaused(true);
			const isLastSession = chosenSideData?.current_session === 4;
			const delay = isLastSession ? 3600 : 10;

			pauseTimeoutId = setTimeout(() => {
				pauseGame(delay);
			}, 1500);
		}
		return () => clearTimeout(pauseTimeoutId);
	}, [currCoins, chosenSideData]);

	const pauseGame = useCallback(
		async (delayInSeconds) => {
			try {
				const response = await fetch(secretURL + '/api/set-event-activity', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						token: await genHash(),
						id_telegram: user?.id_telegram,
						id_event: eventId,
						timestamp: Math.floor(Date.now() / 1000) + delayInSeconds,
					}),
				});
				const responseData = await response.json();

				if (responseData?.active_at) {
					const updatedUser = {
						...user,
						events_gaming: user.events_gaming.map((event) =>
							event.event_gaming_id === eventGamingId
								? {
										...event,
										can_play_at: responseData.active_at,
										votes: event.votes + 1,
								  }
								: event
						),
					};

					setUser(updatedUser);
					setCanPlayAt(responseData.active_at);
					setCurrCoins(0);
				}

				addNotification('success', 'Good job! Your vote matters!');
			} catch (error) {
				addNotification('error', 'Your vote was not counted. Please try again.');
			}
		},
		[genHash, user, secretURL, eventId]
	);

	const boostClickedHandler = () => {
		activateBoost(energyVal, clickNewCoins, setEnergyVal, setClickNewCoins, user);
	};

	const submitData = useCallback(
		async (coins) => {
			if (gamePaused) {
				console.log('Submission blocked: game is paused');
				return;
			}

			const cappedCoins = Math.min(coins, 1000);
			try {
				const token = await genHash();
				const response = await updateEventBalance({
					token,
					id_telegram: user?.id_telegram,
					event_gaming_id: eventGamingId,
					score: cappedCoins,
				}).unwrap();
			} catch (e) {
				console.error('Error during submission:', e);
				setUnsubmittedCoins((prev) => prev + cappedCoins);
			}
		},
		[user, currentEvent, gamePaused]
	);

	function updateBalance() {
		const coinsToSubmit = accumulatedCoinsRef.current + unsubmittedCoins;
		if (coinsToSubmit > 0) {
			submitData(coinsToSubmit);
			accumulatedCoinsRef.current = 0;
			setUnsubmittedCoins(0);
		}
	}

	const debouncedUpdateBalanceRef = useRef(debounce(updateBalance, 1200));

	const handleShowAnimation = (event) => {
		if (!event) return;
		const touch = event.touches ? event.touches[0] : event;
		const x = touch.pageX;
		const y = touch.pageY;
		const id = Date.now();
		setAnimations((prev) => [...prev, { id, x, y }]);
		setIsAnimationActive(true);
	};

	const handleTouchStart = (event) => handleShowAnimation(event);

	const handleTouchEnd = () => {
		const clickValue = clickNewCoins;
		setCurrCoins((prev) => Math.min(prev + clickValue, 1000));
		accumulatedCoinsRef.current += clickValue;

		if (!gamePaused && tapInput) {
			tapInput.fire();
		}

		debouncedUpdateBalanceRef.current();
	};

	const calculateStrokeDasharray = (currCoins) => {
		const circleCircumference = 2 * Math.PI * 45;
		const percentage = (currCoins / 1000) * circleCircumference;
		return `${percentage} ${circleCircumference}`;
	};

	const handleShowLeaders = () =>
		showPopup(<EventLeaders eventId={chosenSideData?.event_gaming_id} />);
	const handleTasks = () => {
		if (tasksReady && matchingTasks.length > 0) {
			showPopup(
				<EventTasks eventId={chosenSideData?.event_gaming_id} tasks={matchingTasks} />
			);
		}
	};

	const handleShowStats = () =>
		showPopup(<EventStats eventId={chosenSideData?.event_id} />);

	const removeAnimation = (id) => {
		setAnimations((prev) => prev.filter((anim) => anim.id !== id));
	};

	const formatTime = (seconds) => {
		const hrs = Math.floor(seconds / 3600);
		const mins = Math.floor((seconds % 3600) / 60);
		const secs = seconds % 60;

		if (hrs > 0) {
			return `${hrs.toString().padStart(2, '0')}:${mins
				.toString()
				.padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
		} else {
			return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
		}
	};

	const startEvent = (gamingId) => {
		localStorage.setItem('gamingId', JSON.stringify(gamingId));
		navigate(`/startEvent`, {
			state: {
				gamingId,
			},
		});
	};

	return (
		<div className='eventWrapper'>
			{isLoading || !currentEvent ? (
				<Preloader />
			) : (
				<>
					<MyButton
						variant='smallIcon'
						style={{ top: '20px', left: '20px' }}
						onClick={navBack}
					>
						<Icons.Larrow />
					</MyButton>
					<MyButton
						variant='smallIcon'
						style={{ top: '20px', right: '20px' }}
						onClick={handleShowLeaders}
					>
						<Icons.CupWhite />
					</MyButton>

					<MyButton
						variant='smallIcon'
						style={{ top: '82px', right: '20px' }}
						onClick={handleShowStats}
					>
						<Icons.StatsIcon />
					</MyButton>

					<MyBalance>
						<div style={{ fontSize: '18px' }}>
							<span>Your votes:</span>
						</div>
						<div style={{ margin: '0', fontSize: '38px' }}>
							{chosenSideData?.votes ?? 0}
							<Icons.Shit />
						</div>
					</MyBalance>

					{!gamePaused && visible ? (
						<motion.div
							initial={{
								y: 7,
								rotate: 0,
								opacity: 1,
							}}
							animate={{
								y: [5, 15, 20],
								x: [-15, 15, 5],
							}}
							transition={{
								duration: 4,
								repeat: Infinity,
								repeatType: 'mirror',
								ease: 'easeInOut',
							}}
							style={{
								position: 'absolute',
								top: '50%',
								left: 0,
								zIndex: 150,
							}}
						>
							<motion.div
								animate={{
									opacity: [0, 1],
								}}
								transition={{
									duration: 4,
									repeat: Infinity,
									repeatType: 'mirror',
									ease: 'easeInOut',
								}}
							>
								<div
									className='boost-element'
									style={{
										position: 'absolute',
										overflow: 'hidden',
										left: `${position.x}px`,
										top: `${position.y}px`,
										cursor: 'pointer',
										width: '100px',
										height: '90px',
										zIndex: 25,
									}}
									onClick={boostClickedHandler}
								>
									<motion.img
										src={boostIcon}
										alt='Boost'
										style={{
											width: '100%',
											height: '100%',
											userSelect: 'none',
											scale: '100%',
										}}
										initial={{ opacity: 0, rotate: 0 }}
										animate={{ opacity: 1, rotate: 0 }}
										transition={{
											duration: 4,
											repeat: Infinity,
											repeatType: 'mirror',
											ease: 'easeInOut',
										}}
									/>
								</div>
							</motion.div>
						</motion.div>
					) : null}

					<div
						className='event__clickArea'
						onTouchStart={!gamePaused ? handleTouchStart : undefined}
						onTouchEnd={!gamePaused ? handleTouchEnd : undefined}
					>
						<AnimatePresence>
							{isAnimationActive &&
								animations.map((anim) => (
									<motion.div
										key={anim.id}
										className='clickerAnimation'
										initial={{ opacity: 1, y: 0 }}
										animate={{
											opacity: [1, 0],
											x: [-20, -20],
											y: window.innerHeight <= 685 ? [-125, -200] : [-200, -280],
										}}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.5 }}
										style={{
											color: '#000',
											fontSize: '52px',
											left: `${anim.x}px`,
											top: `${anim.y}px`,
											position: 'absolute',
											color: '#333333',
											zIndex: 10,
										}}
										onAnimationComplete={() => removeAnimation(anim.id)}
									>
										<div className='clicker__clickValue'>
											<img src={eventFlag} alt='Paper Plane' />
										</div>
									</motion.div>
								))}
						</AnimatePresence>

						<div className='event__clickerHero'>{EventHeroComponent}</div>
						<div className='event__progressBox'>
							<div className='event__progressBar'>
								<svg
									viewBox='0 0 100 100'
									style={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										borderRadius: '100%',
									}}
								>
									<defs>
										<filter id='boxShadow' x='-20%' y='-20%' width='140%' height='140%'>
											<feGaussianBlur in='SourceAlpha' stdDeviation='3' />
											<feOffset dx='0' dy='0' result='offsetblur' />
											<feComponentTransfer>
												<feFuncA type='linear' slope='0.5' />
											</feComponentTransfer>
											<feMerge>
												<feMergeNode />
												<feMergeNode in='SourceGraphic' />
											</feMerge>
										</filter>
									</defs>

									<circle
										cx='50'
										cy='50'
										r='45'
										fill='none'
										stroke='#EEE'
										strokeWidth='2.5'
									></circle>
									{currCoins > 0 && (
										<circle
											cx='50'
											cy='50'
											r='45'
											fill='none'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeDasharray={calculateStrokeDasharray(currCoins)}
											style={{ transition: 'stroke-dasharray 0.35s' }}
											stroke='#000'
										></circle>
									)}
								</svg>
							</div>
						</div>
					</div>

					<div className='event__eventVotes'>
						{!gamePaused && (
							<div className='event__eventVotes-left'>
								<div className='event__eventVotes-text'>
									<span>Session:</span>
								</div>
								<div className='event__eventVotes-number'>
									<span>{chosenSideData?.current_session}/4</span>
									<Icons.Shit />
								</div>
							</div>
						)}

						<div className='event__eventVotes-right'>
							<div className='event__eventVotes-text'>
								{gamePaused ? (
									<>
										{timeRemaining && (
											<span style={{ marginLeft: '-14px' }}>Next session in:</span>
										)}
									</>
								) : (
									<span>Taps:</span>
								)}
							</div>
							<div className='event__eventVotes-number'>
								{gamePaused ? (
									timeRemaining ? (
										<span style={{ marginLeft: '-14px', marginRight: '0px' }}>
											{formatTime(timeRemaining)}
										</span>
									) : (
										<span style={{ marginLeft: '-14px', marginRight: '0px' }}>
											Calculating...
										</span>
									)
								) : (
									<>
										<span>{`${currCoins}/${user?.max_energy ?? 1000}`}</span>
										<Icons.CoinIcon />
									</>
								)}
							</div>
						</div>
					</div>

					<div
						className='event__tip'
						onClick={() => startEvent(chosenSideData?.event_gaming_id)}
					>
						Tap for win and get rewards! <Icons.Info />
					</div>
					<MyButton variant='black' onClick={handleTasks} className='mainBtn'>
						tasks <span style={{ color: 'black' }}>{activeTasksCount}</span>
					</MyButton>
				</>
			)}
		</div>
	);
}
