import { useEffect, useState, useRef } from 'react';
import React from 'react';
import NavigationWidget from '../NavigationWidget';
import ContentLoader from 'react-content-loader';
import Icons from '../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../Popups/PopupContext';
import { MyButton, MyTitle } from '../../../Common/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from '../Preloader/Preloader';

export default function ProjectsScreen() {
	// const [allProjects, setAllProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	// const [categories, setCategories] = useState([]);
	// const [activeCategory, setActiveCategory] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const { user } = usePopup();
	const [containerWidth, setContainerWidth] = useState(0);
	const containerRef = useRef(null);
	const location = useLocation();
	// const [activityId, setActivityId] = useState([]);
	const [loadingScreen, setLoadingScreen] = useState(true);
	const [categoryProjects, setCategoryProjects] = useState([]);
	let activityName = location.state.activityName;
	if (!activityName) {
		activityName = localStorage.getItem('activityName')
			? JSON.parse(localStorage.getItem('activityName'))
			: 'Unknown Activity';
	}
	let activityId = location.state.activityId;

	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	useEffect(() => {
		if (loadingScreen) {
			const timer = setTimeout(() => {
				setLoadingScreen(false);
			}, 1000);

			return () => clearTimeout(timer);
		}
	}, [loadingScreen]);

	useEffect(() => {
		if (location.state && location.state.categoryProjects) {
			setCategoryProjects(location.state.categoryProjects);
		} else {
			const storedProjects = localStorage.getItem('categoryProjects');
			if (storedProjects) {
				setCategoryProjects(JSON.parse(storedProjects));
			} else {
				console.log('No projects found');
			}
		}
	}, [location.state]);

	useEffect(() => {
		if (!user) navigate('/home');
	}, [user, navigate]);

	useEffect(() => {
		const fetchProjects = async () => {
			setIsLoading(true);
			try {
				const response = await fetch(
					`https://admin.theshit.io/api/activity/${activityId}`
				);
				const data = await response.json();
				if (Array.isArray(data)) {
					// setAllProjects(data);
					setFilteredProjects(data);
					// localStorage.setItem('projectsData', JSON.stringify(data));
				} else {
					// setAllProjects([]);
					setFilteredProjects([]);
				}
			} catch (error) {
				console.error('Error fetching projects data:', error);
				// setAllProjects([]);
				setFilteredProjects([]);
			} finally {
				setIsLoading(false);
			}
		};
		fetchProjects();
	}, [activityId]);

	// useEffect(() => {
	//     const fetchCategories = async () => {
	//         try {
	//             const res = await getActivity();
	//             if (res?.data) {
	//                 setCategories(res.data);
	//                 if (activityId) {
	//                     filterProjectsByCategory(res.data, activityId);
	//                 }
	//             }
	//         } catch (error) {
	//             console.error('Error fetching categories:', error);
	//         }
	//     };
	//     fetchCategories();
	// }, [getActivity, activityId]);

	// Фильтрация проектов по категории
	// const filterProjectsByCategory = (categories, selectedCategory) => {
	//     if (allProjects.length === 0 || !categories) return;

	//     const categoryObj = categories.find(cat => cat.name === selectedCategory);
	//     if (!categoryObj) return;

	//     const filtered = allProjects.filter(project => project.category_id === categoryObj.id);
	//     setFilteredProjects(filtered);
	//     setActiveCategory(selectedCategory);
	// };

	// const handleCategoryChange = (newCategory) => {
	//     filterProjectsByCategory(categories, newCategory);
	// };

	const handleProjectClick = (project) => {
		navigate(`/projects/${project.id}`);
	};

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	const numberOfRects = 12;
	const rects = Array.from({ length: numberOfRects }, (_, i) => (
		<rect key={i} x='0' y={i * 51} rx='16' ry='16' width={containerWidth} height='45' />
	));

	const renderTabContent = () => {
		return (
			<ul className='ratings__list'>
				{filteredProjects.map((project, index) => (
					<li
						className={`ratings__item ${
							project.has_game === 1 ? 'ratings__highlighted' : ''
						}`}
						key={index}
						onClick={() => handleProjectClick(project)}
					>
						<div className='ratings__image'>
							{project.has_game === 1 && (
								<div
									style={{
										position: 'absolute',
										display: 'flex',
										alignItems: 'center',
										zIndex: 10,
									}}
								>
									<Icons.ProjEvent />
								</div>
							)}
							<img src={`${imageBaseUrl}${project.image}`} alt={project.name} />
						</div>
						<div className='ratings__name'>{project.name}</div>
						<div className='ratings__value'>
							{project.vote_total}
							<Icons.Shit />
						</div>
					</li>
				))}
			</ul>
		);
	};

	const navBack = () => {
		navigate(`/activity`);
	};

	return (
		<>
			{loadingScreen ? ( // Якщо завантаження триває, показуємо прелоадер
				<Preloader />
			) : (
				<div className='ratings'>
					<div className='ratings__container container'>
						<div className='ratings__body' style={{ height: '100%' }} ref={containerRef}>
							<MyButton variant='smallIcon' onClick={() => navBack()}>
								<Icons.Larrow />
							</MyButton>
							<MyTitle style={{ margin: '14px 0 12px 68px' }}>{activityName}</MyTitle>
							{/* <div className='ratings__links'>
                            {categories.map((cat) => (
                                <MyButton
                                    key={cat.id}
                                    variant='smallTab'
                                    className={`${activeCategory === cat.name ? 'active' : ''}`}
                                    onClick={() => handleCategoryChange(cat.name)}
                                >
                                    {cat.name}
                                </MyButton>
                            ))}
                        </div> */}
							{isLoading ? (
								<ContentLoader
									speed={1}
									width='100%'
									height='100%'
									viewBox={`0 0 ${containerWidth} 600`}
									backgroundColor='#f3f3f3'
									foregroundColor='#ecebeb'
								>
									{rects}
								</ContentLoader>
							) : (
								renderTabContent()
							)}
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
}
