import React from 'react';
import { MyButton } from '../../../../../Common/styles';
import Icons from '../../../../../Common/IconsComponent';
import Count from './components/Count';
import { getTelegramShareLink } from '../../../../../../helpers/referralLinkHelper';

const EventSlide = ({
	event,
	user,
	imageBaseUrl,
	formatNumber,
	navBack,
	startEvent,
	continueEvent,
	handleShowLeaders,
	hasJoinedEvent,
}) => {
	const blueVotes = event.gaming?.[1]?.total_votes || 0;
	const redVotes = event.gaming?.[0]?.total_votes || 0;
	const totalVotes = blueVotes + redVotes;
	const bluePercentage = totalVotes === 0 ? 50 : (blueVotes / totalVotes) * 100;
	const redPercentage = totalVotes === 0 ? 50 : (redVotes / totalVotes) * 100;

	const joinedEvent = hasJoinedEvent([event.gaming?.[0]?.id, event.gaming?.[1]?.id]);
	const winningTeam =
		event.status === 2 ? event.gaming?.find((g) => g.winner === 1) : null;

	return (
		<div className='projects__slide'>
			<div className='projects__card' style={{ padding: '18px' }}>
				<MyButton
					onClick={navBack}
					variant='smallIcon'
					style={{ top: '20px', left: '20px' }}
				>
					<Icons.Larrow />
				</MyButton>
				<MyButton
					variant='smallIcon'
					style={{ top: '20px', right: '20px' }}
					onClick={() => {
						const telegramId = user.id_telegram;
						const shareLink = getTelegramShareLink(telegramId, null, event.id);
						window.open(shareLink, '_blank');
					}}
				>
					<Icons.Invite />
				</MyButton>
				<div style={{ width: '100%' }}>
					{event.status === 2 && winningTeam && (
						<div className='projects__title'>
							<h2>Winner</h2>
							<p>{winningTeam.name} team won!</p>
							<div className='projects__title-votes'>
								{winningTeam.name}’s Votes:&nbsp;
								<span>{formatNumber(winningTeam.total_votes)}</span>
							</div>
						</div>
					)}
					{event.status !== 2 && (
						<div className='projects__time'>
							<span>
								<Icons.Clock /> {event.status === 1 ? 'Ends in:' : 'Starts in:'}
							</span>
							<span style={{ marginTop: '10px' }}>
								<Count time={event.status === 1 ? event.end_time : event.start_time} />
							</span>
						</div>
					)}
					{event.status !== 2 && (
						<div className='projects__progress'>
							Votes
							<div
								style={{
									width: '100%',
									height: '12px',
									borderRadius: '18px',
									overflow: 'hidden',
									background: `linear-gradient(to right, #007AFF ${bluePercentage}%, #FF0000 ${bluePercentage}%)`,
								}}
							/>
							<div className='projects__progress-wrap'>
								<span>
									{blueVotes} <Icons.Shit />
								</span>
								<span>
									{redVotes} <Icons.Shit />
								</span>
							</div>
						</div>
					)}
				</div>
				<div className='projects__sides'>
					{event.gaming.map((team, index) => (
						<div
							key={team.id}
							className='projects__sides-item'
							style={{
								opacity:
									event.status === 2 ? '0.5' : hasJoinedEvent([team.id]) ? '1' : '0.5',
							}}
						>
							<div className='projects__sides-img'>
								<img
									style={{
										border:
											hasJoinedEvent([team.id]) && event.status !== 2
												? '2px solid #000'
												: 'none',
									}}
									src={`${imageBaseUrl}${team.image}`}
									alt={team.name}
								/>
							</div>
							<div className='projects__sides-name'>
								{hasJoinedEvent([team.id]) && event.status !== 2 && <Icons.CheckBlack />}
								<span>{team.name}</span>
							</div>
							{user && user.events_gaming && event.status !== 2 && (
								<div className='projects__sides-amount'>
									<Icons.User />
									<span>{formatNumber(team.total_joined)}</span>
								</div>
							)}
						</div>
					))}
				</div>

				{/* Buttons for Joining, Continuing, or Viewing Results */}
				{joinedEvent && event.status === 0 && (
					<div className='projects__continue'>
						<MyButton disabled variant='inactive'>
							Wait for start
						</MyButton>
					</div>
				)}

				{joinedEvent && event.status === 1 && (
					<div className='projects__continue'>
						<MyButton variant='black' onClick={() => continueEvent(joinedEvent)}>
							Play and win
						</MyButton>
					</div>
				)}

				{!joinedEvent && (event.status === 0 || event.status === 1) && (
					<div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
						{event.gaming.map((team) => (
							<div
								className='projects__sides-btn'
								style={{ width: '140px' }}
								key={team.id}
							>
								<MyButton
									onClick={() =>
										startEvent(
											team.id,
											event.pay_value_shit,
											event.pay_value_stars,
											event.id,
											event.status,
											team.image
										)
									}
									variant='black'
								>
									join team
								</MyButton>
							</div>
						))}
					</div>
				)}

				{winningTeam && (
					<div className='projects__continue'>
						<div className='projects__continue-title'>
							<h2>Event Finished!</h2>
						</div>
						<MyButton onClick={() => handleShowLeaders(winningTeam.id)}>
							Total Score
						</MyButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default EventSlide;
