import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Icons from '../../../../../Common/IconsComponent.jsx';
import { MyButton } from '../../../../../Common/styles';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import StartEventModal from './StartEventScreenModal/StartEventScreenModal.jsx';
import Preloader from '../../../Preloader/Preloader.jsx';
import './StartEvent.scss';
import { useEventWinnerPopup } from '../../../../../../helpers/eventNotify.js';

const StartEvent = () => {
	const { showPopup, user } = usePopup();
	useEventWinnerPopup(user, showPopup);
	const location = useLocation();
	const { gamingId, payValueShit, payValueStars, eventId, eventStatus, image } =
		location.state || JSON.parse(localStorage.getItem('eventData'));
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	const navBack = () => {
		navigate(`/events/${eventId}`);
	};

	useEffect(() => {
		if (location.state) {
			localStorage.setItem('eventData', JSON.stringify(location.state));
		}
	}, [location.state]);

	const startEvent = () => {
		if (user?.id_telegram) {
			showPopup(
				<StartEventModal
					id_telegram={user?.id_telegram}
					gamingId={gamingId}
					payValueShit={payValueShit}
					payValueStars={payValueStars}
					walletBalance={user?.wallet_balance}
					eventStatus={eventStatus}
					eventId={eventId}
					navigate={navigate}
				/>
			);
		} else {
			console.error('id_telegram is missing!');
		}
	};

	const continueEvent = () => {
		navigate(`/events/${gamingId}/game`);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			{loading ? (
				<Preloader />
			) : (
				<div className='startEvent'>
					<MyButton
						onClick={navBack}
						variant='smallIcon'
						style={{ top: '20px', left: '20px' }}
					>
						<Icons.Larrow />
					</MyButton>
					<MyButton
						variant='smallIcon'
						style={{ top: '20px', right: '20px' }}
						onClick={continueEvent}
					>
						<Icons.Rarrow />
					</MyButton>
					<div className='container startEvent__container'>
						<div className='startEvent__img'>
							<img src={`${imageBaseUrl}${image}`} />
						</div>
						<div className='startEvent__icon'>
							<Icons.Money />
						</div>
						<div className='startEvent__title'>
							<span>
								Pay{' '}
								<b>
									{' '}
									250 <Icons.StarBlack /> Stars
								</b>{' '}
								or{' '}
								<b>
									1000 <Icons.Shit /> Shit
								</b>{' '}
								points to start the game
							</span>
						</div>
						<div className='startEvent__descr'>
							<span>
								Each team can have as many players as possible to start voting and invite
								others to vote. This challenge ends on <b>November 5th</b> but may be
								extended.
							</span>
						</div>
						{/* <div className='startEvent__descr'>
							<span>
								The challenge has a <b>2 weeks</b> limit
							</span>
						</div> */}
						<div className='startEvent__item-btn' style={{ width: '265px' }}>
							<MyButton onClick={startEvent}>pay to start</MyButton>
						</div>
					</div>
					<div className='startEvent__item'>
						<div className='container'>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Entry Fee:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>Pay 250 Stars or 1000 $hit Points to participate!</span>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Prizes</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>
										Finish in the <b>Top 100</b> to win a <b>TG Stars</b> prize from our
										bank!
									</span>
									<ul>
										<li>
											<span>
												<b>20%</b> goes to the <b>$HIT Bank fees.</b>
											</span>
										</li>
										<li>
											<span>
												<b>50%</b> shared among the <b>Top 100</b> players from the
												winning team.
											</span>
										</li>
										<li>
											<span>
												<b>30%</b> awarded to 50 random players from the winning team.
											</span>
										</li>
									</ul>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Team Up:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>
										Assemble your crew! Bring more frens and make your candidate win.
									</span>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Challenge Duration:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>You have 2 weeks to showcase your skills!</span>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Game Mechanics:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>
										Tap to Vote! Vote & Earn! Smash that Tap button, crush tasks, and
										stack up votes for your candidate!
									</span>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Boost Your Team:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>
										Stay active, invite your squad, and help your team dominate!
									</span>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Tasks:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>Rack up points from quest and bonus tasks!</span>
								</div>
							</div>
							<div className='startEvent__item-box'>
								<div className='startEvent__item-header'>
									<span>Rankings:</span>
								</div>
								<div className='startEvent__item-descr'>
									<span>
										Climb the voting ranks, be the top-performing voter, and claim the
										grand prize!
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default StartEvent;
