import React, { useState, useEffect, useRef } from 'react';
import NavigationWidget from '../NavigationWidget';
import { MyTitle } from '../../../Common/styles';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import Preloader from '../Preloader/Preloader';
import {
	useGetActivityMutation,
	useGetEventsMutation,
} from '../../../../services/phpService';
import './Activity.scss';
import { useEventWinnerPopup } from '../../../../helpers/eventNotify';
import { usePopup } from '../../../Popups/PopupContext';

const ActivityScreen = () => {
	const { user, showPopup } = usePopup();
	useEventWinnerPopup(user, showPopup);
	const [getActivity] = useGetActivityMutation();
	const [getEvents] = useGetEventsMutation();
	const [activityData, setActivityData] = useState([]);
	const [eventsData, setEventsData] = useState([]);
	const [activityLoading, setActivityLoading] = useState(true);
	const [eventsLoading, setEventsLoading] = useState(true);
	const navigate = useNavigate();
	const containerRef = useRef(null);
	const [containerWidth, setContainerWidth] = useState(0);
	const [isPreloading, setIsPreloading] = useState(true);

	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	useEffect(() => {
		const fetchActivities = async () => {
			try {
				const res = await getActivity().unwrap();
				setActivityData(res);
			} catch (error) {
				console.error(error);
			} finally {
				setActivityLoading(false);
			}
		};
		fetchActivities();
	}, [getActivity]);

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const res = await getEvents().unwrap();
				setEventsData(res);
			} catch (error) {
				console.error(error);
			} finally {
				setEventsLoading(false);
			}
		};
		fetchEvents();
	}, [getEvents]);

	const handleNavigateToProjects = (activityId, categoryProjects, activityName) => {
		localStorage.setItem('activityId', JSON.stringify(activityId));
		localStorage.setItem('categoryProjects', JSON.stringify(categoryProjects));
		localStorage.setItem('activityName', JSON.stringify(activityName));
		navigate('/projects', { state: { activityId, categoryProjects, activityName } });
	};

	const handleNavigateToProject = (projectId, categoryProjects) => {
		localStorage.setItem('categoryProjects', JSON.stringify(categoryProjects));
		navigate(`/projects/${projectId}`, { state: { categoryProjects } });
	};

	const handleNavigateToEvent = (eventId) => {
		navigate(`/events/${eventId}`);
	};

	const preloadImages = async () => {
		const allImageUrls = [
			...eventsData.map((event) => `${imageBaseUrl}/${event.image}`),
			...activityData.flatMap((activity) =>
				activity.projects.slice(0, 4).map((project) => `${imageBaseUrl}${project.image}`)
			),
		];

		await Promise.all(
			allImageUrls.map(
				(src) =>
					new Promise((resolve) => {
						const img = new Image();
						img.src = src;
						img.onload = resolve;
						img.onerror = resolve;
					})
			)
		);
	};

	useEffect(() => {
		const minPreloaderDuration = 1000;
		const start = Date.now();

		const loadDataAndImages = async () => {
			await Promise.all([preloadImages()]);
			const elapsed = Date.now() - start;
			const remainingTime = minPreloaderDuration - elapsed;

			setTimeout(
				() => {
					setIsPreloading(false);
				},
				remainingTime > 0 ? remainingTime : 0
			);
		};

		loadDataAndImages();
	}, [eventsData, activityData]);

	return (
		<>
			{isPreloading ? (
				<Preloader />
			) : (
				<div className='activity'>
					<div className='container' ref={containerRef}>
						<MyTitle>vote & earn</MyTitle>
						<div
							className='activity__event-banner'
							onClick={() => handleNavigateToEvent(eventsData[0].id)}
						>
							<img
								src={`${imageBaseUrl}/${eventsData[0].image}`}
								alt={eventsData[0].name}
								loading='lazy'
							/>
						</div>
						<div className='activity__cats'>
							{activityLoading || eventsLoading ? (
								<ContentLoader
									speed={2}
									width={'100%'}
									height={6 * 58}
									viewBox={`0 0 ${containerWidth} ${6 * 58}`}
									backgroundColor='#f3f3f3'
									foregroundColor='#ecebeb'
								>
									{Array.from({ length: 6 }).map((_, i) => (
										<rect
											key={i}
											x='0'
											y={i * 58}
											rx='16'
											ry='16'
											width={containerWidth}
											height='52'
										/>
									))}
								</ContentLoader>
							) : (
								activityData.map((activity) => (
									<div className='activity__cat' key={activity.id}>
										<div className='activity__cat-item'>
											<span>{activity.name}</span>
											<div className='activity__thumbnails'>
												{activity.projects.slice(0, 4).map((project) => (
													<div className='activity__thumbnail-img' key={project.id}>
														<img
															src={`${imageBaseUrl}${project.image}`}
															alt={`проект ${project.id}`}
															loading='lazy'
															onClick={() =>
																handleNavigateToProject(project.id, activity.projects)
															}
														/>
													</div>
												))}
												{activity.total_projects > 4 && (
													<div className='activity__thumbnail-extra'>
														+{activity.total_projects - 4}
													</div>
												)}
											</div>
										</div>
										<div className='activity__cat-item'>
											<button
												onClick={() =>
													handleNavigateToProjects(
														activity.id,
														activity.projects,
														activity.name
													)
												}
											>
												view
											</button>
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
};

export default ActivityScreen;
