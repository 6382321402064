import styled from 'styled-components';

const MyAnnounce = styled.div`
	background: #000;
	border-radius: 24px;
	font-weight: 400;
	color: #fff;
	padding: 12px 18px 18px 18px;
	height: 96px;
	display: flex;
	gap: 19px;
	align-items: center;
	justify-content: space-between;
	h2 {
		font-size: 24px;
	}
	p {
		font-size: 14px;
	}
	svg {
		width: 13px;
		height: 12px;
		margin-left: 6px;
	}
	button {
		margin-top: 6px;
		height: 38px;
		padding: 0 14px;
		background: #fff500;
		color: #000;
		border-radius: 44px;
		display: flex;
		align-items: center;
		font-size: 22px;
	}
`;

export default MyAnnounce;
