import './App.scss';
import React from 'react';
import { Helmet } from 'react-helmet';
import './assets/scss/style.scss';
import 'animate.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import SplashScreen from './components/Pages/Splash/SplashScreen.jsx';
import HomeScreen from './components/Pages/Home/HomeScreen.jsx';
import ProjectsScreen from './components/Pages/Home/Projects/ProjectsScreen.jsx';
import RatingsScreen from './components/Pages/Home/Ratings/RatingsScreen.jsx';
import ErrorScreen from './components/Pages/ErrorScreen.jsx';
import Event from './components/Pages/Home/Activity/Events/Event/Event';
import ProfileScreen from './components/Pages/Home/Profile/ProfileScreen.jsx';
import ProjectsSlider from './components/Pages/Home/Projects/ProjectsSlider/ProjectsSlider';
import ProjectClicker from './components/Pages/Home/Projects/Project/ProjectClicker';
import ActivityScreen from './components/Pages/Home/Activity/ActivityScreen.jsx';
import EventScreen from './components/Pages/Home/Activity/Events/EventScreen/EventScreen.jsx';
import ProjectRef from './components/Pages/Home/Projects/ProjectsSlider/ProjectRef';
import StartEvent from './components/Pages/Home/Activity/Events//StartEventScreen/StartEvent.jsx';
import { PopupProvider } from './components/Popups/PopupContext.js';
import Popup from './components/Popups/Popup.jsx';
import { store } from './services/store.js';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import NotificationsContainer from './components/Notification/NotificationsContainer.jsx';
import Maintenance from './components/Pages/Status/Maintenance';

const router = createBrowserRouter([
	{
		path: '/',
		element: <SplashScreen />,
	},
	{
		path: '/home',
		element: <HomeScreen />,
	},
	{
		path: '/events/:eventSideId/game',
		element: <Event />,
	},
	{
		path: '/events/:eventId',
		element: <EventScreen />,
	},
	{
		path: '/startEvent',
		element: <StartEvent />,
	},
	{
		path: '/home/*',
		element: <HomeScreen />,
	},
	{
		path: '/profile',
		element: <ProfileScreen />,
	},
	{
		path: '/ratings',
		element: <RatingsScreen />,
	},
	{
		path: '/activity',
		element: <ActivityScreen />,
	},
	{
		path: '/projects',
		element: <ProjectsScreen />,
	},
	{
		path: '/projects/:projectId',
		element: <ProjectsSlider />,
	},
	{
		path: '/project/:projectId',
		element: <ProjectRef />,
	},
	{
		path: '/projects/:projectId/game',
		element: <ProjectClicker />,
	},
	{
		path: '*',
		element: <ErrorScreen />,
	},
]);

function App() {
	return (
		<>
			<Helmet>
				<script>
					{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P8CSNCKG');
          `}
				</script>
			</Helmet>

			<Provider store={store}>
				<TonConnectUIProvider
					manifestUrl='https://sht-app.prgss.dev/tonconnect-manifest.json'
					actionsConfiguration={{
						twaReturnUrl: 'https://t.me/cln_inline_bot',
					}}
				>
					<PopupProvider>
						<RouterProvider router={router} />
						<Popup />
						<NotificationsContainer />
					</PopupProvider>
				</TonConnectUIProvider>
			</Provider>
			{/* <Maintenance/> */}
		</>
	);
}

export default App;
