import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import Project from '../Project/Project';
import NavigationWidget from '../../NavigationWidget';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import './ProjectsSlider.scss';
import useFetchProjects from '../../../../../helpers/fetchProjects';
import { usePopup } from '../../../../Popups/PopupContext.js';
import Preloader from '../../Preloader/Preloader.jsx';

export default function ProjectsSlider() {
	const [projects, setProjects] = useState([]);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const { projectId } = useParams();
	const { user } = usePopup();
	const navigate = useNavigate();
	const location = useLocation();
	const [categoryProjects, setCategoryProjects] = useState([]);
	// const [filteredProjects, setFilteredProjects] = useState([]);
	const [loadingSlider, setLoadingSlider] = useState(true);
	const projectIndex = projects.findIndex((proj) => proj.id === projectId);

	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	useEffect(() => {
		if (location.state && location.state.categoryProjects) {
			setCategoryProjects(location.state.categoryProjects);
		} else {
			const storedProjects = localStorage.getItem('categoryProjects');
			if (storedProjects) {
				setCategoryProjects(JSON.parse(storedProjects));
			} else {
				console.log('No projects found');
			}
		}
	}, [location.state]);

	const { projectsData, fetchAllProjects, loading } = useFetchProjects();

	useEffect(() => {
		fetchAllProjects();
	}, []);

	useEffect(() => {
		const redirect_path = location.pathname.substring(1);
		if (!user) navigate('/?r=' + redirect_path);
	}, []);

	useEffect(() => {
		if (!loading && projectsData && categoryProjects.length > 0) {
			const categoryProjectIds = categoryProjects.map((project) => project.id);

			const filtered = projectsData.filter((project) =>
				categoryProjectIds.includes(project.id)
			);

			const mappedProjects = filtered.map((project, index) => {
				const valueVoters =
					project.total_voters && project.total_voters.length > 0
						? project.total_voters[0].total_voters
						: null;

				return {
					id: project.id,
					value: project.vote_total || null,
					valueVoters,
					rank: index + 1,
					has_game: project.has_game,
					imgSrc: `${imageBaseUrl}${project.image}`,
					name: project.name,
					tokenName: project.tokenName || null,
					contract: project.contract || null,
					tasksCount: `${project.active_tasks.length}`,
					tasks: project.active_tasks.map((task) => ({
						taskId: task.id,
						name: task.name,
						value: `+${task.reward}`,
						link: task.link || 'home',
						imgSrc: `${imageBaseUrl}${project.image}`,
					})),
				};
			});

			setProjects(mappedProjects);

			const index = mappedProjects.findIndex(
				(project) => project.id.toString() === projectId
			);
			setInitialSlideIndex(index >= 0 ? index : 0);
		}
	}, [imageBaseUrl, projectId, loading, projectsData, categoryProjects]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoadingSlider(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			{loadingSlider ? (
				<Preloader />
			) : (
				projects.length > 0 && (
					<div className='projects'>
						<div className='projects__body'>
							<div className='projects__slider'>
								<Swiper
									// activeIndex={projectIndex}
									modules={[Navigation]}
									spaceBetween={50}
									slidesPerView={1}
									loop={true}
									initialSlide={initialSlideIndex}
								>
									{projects.map((project) => (
										<SwiperSlide key={project.id}>
											<Project key={project.id} project={project} />
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
						<NavigationWidget />
					</div>
				)
			)}
		</>
	);
}
