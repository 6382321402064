import React, { useState, useEffect } from 'react';
import { usePopup } from './PopupContext';
import ClickerLeaders from '../Pages/Home/Projects/Project/ClickerLeaders';
import EventLeaders from '../Pages/Home/Activity/Events/Event/EventLeaders';

const Popup = () => {
	const { isPopupOpen, popupContent, hidePopup } = usePopup();
	const [showForm, setShowForm] = useState(false);

	const handleSP = () => {
		setShowForm((prevShowForm) => !prevShowForm);
	};

	const closeModal = () => {
		hidePopup();
	};

	const isClickerLeadersContent =
		React.isValidElement(popupContent) && popupContent.type === ClickerLeaders;

	const isEventLeadersContent =
		React.isValidElement(popupContent) && popupContent.type === EventLeaders;

	const wrapperStyle = {
		position: isClickerLeadersContent
			? 'static'
			: isEventLeadersContent
			? 'relative'
			: 'absolute',
		height: isEventLeadersContent ? '100%' : 'auto',
	};

	useEffect(() => {
		if (isPopupOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [isPopupOpen]);

	return (
		<div onClick={hidePopup}>
			<div className={`modal ${isPopupOpen ? '_active' : ''}`}>
				<div
					className='modal__wrapper'
					style={wrapperStyle}
					onClick={(e) => e.stopPropagation()}
				>
					<div
						className={`modal__content ${
							isClickerLeadersContent || isEventLeadersContent ? 'white_bg' : 'black_bg'
						}`}
					>
						{popupContent}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Popup;
