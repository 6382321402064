import React, { useState, useEffect, useCallback } from 'react';
import { usePopup } from '../../../../Popups/PopupContext';
import {
	useVoteMutation,
	usePassTaskMutation,
	useGetProjectByIdMutation,
	useGetActivityMutation,
} from '../../../../../services/phpService';
import Preloader from '../../Preloader/Preloader';
import NavigationWidget from '../../NavigationWidget';
import { getTelegramShareLink } from '../../../../../helpers/referralLinkHelper';
import { useParams } from 'react-router-dom';
import { MyButton, MyBalance } from '../../../../Common/styles';
import { useNavigate } from 'react-router-dom';
import useFetchProjects from '../../../../../helpers/fetchProjects';
import ProjectTasks from '../Project/ProjectTasks';
import Icons from '../../../../Common/IconsComponent';

const ProjectRef = () => {
	const [getProject] = useGetProjectByIdMutation();
	const [getActivity] = useGetActivityMutation();
	const { genHash, addNotification, user, setUser, showPopup } = usePopup();
	const [totalVotes, setTotalVotes] = useState(null);
	const [totalVoters, setTotalVoters] = useState(null);
	const [voteTime, setVoteTime] = useState(0);
	const [isVoteReady, setIsVoteReady] = useState(true);
	const [isVoteLoading, setVoteLoading] = useState(false);
	const [isVoteBlock, setIsVoteBlock] = useState(true);
	const [blockTime, setBlockTime] = useState(10);
	const [disabledButtons, setDisabledButtons] = useState({});
	const [vote] = useVoteMutation();
	const [checkTasks] = usePassTaskMutation();
	const [project, setProject] = useState(null);
	const [activityData, setActivityData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const { projectId } = useParams();

	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	const { projectsData, fetchAllProjects, loading } = useFetchProjects();
	useEffect(() => {
		fetchAllProjects();
	}, []);

	useEffect(() => {
		const fetchGetProjectById = async () => {
			try {
				const res = await getProject(projectId).unwrap();
				setProject(res);
			} catch (error) {
				console.error(error);
			}
		};
		fetchGetProjectById();
	}, [getProject]);

	useEffect(() => {
		const fetchActivities = async () => {
			try {
				const res = await getActivity().unwrap();
				setActivityData(res);
			} catch (error) {
				console.error(error);
			}
		};
		fetchActivities();
	}, [getActivity]);

	useEffect(() => {
		if (project && activityData) {
			setIsLoading(false);
		}
	}, [project, activityData]);

	const refProject = projectId;
	const TIMER_STORAGE_KEY = 'project_vote_timers';

	const handleImageClick = () => {
		if (project?.has_game === 1) {
			navigate(`/projects/${refProject}/game`);
		}
	};

	const handleButtonClick = (taskId, link, isTaskCompleted) => {
		if (!isTaskCompleted) {
			checkTask();
		} else {
			addNotification('info', 'Task is already competed');
		}

		openLink(link);
		// saveAction(user?.id_telegram, 'click_proj_task', user?.parent_id, project.id, taskId);

		setDisabledButtons((prev) => ({ ...prev, [taskId]: true }));

		setTimeout(() => {
			setDisabledButtons((prev) => ({ ...prev, [taskId]: false }));
		}, 5000);
	};

	useEffect(() => {
		if (!user) navigate(`/?r=project/${refProject}`);

		const interval = setInterval(() => {
			setBlockTime((prev) => {
				if (prev <= 1) {
					clearInterval(interval);
					setIsVoteBlock(false);
					return 0;
				}
				return prev - 1;
			});
		}, 1000);
	}, [user]);

	const getStoredTimers = useCallback(() => {
		const storedTimers = localStorage.getItem(TIMER_STORAGE_KEY);
		return storedTimers ? JSON.parse(storedTimers) : {};
	}, []);

	const getRemainingTime = useCallback(() => {
		const storedTimers = getStoredTimers();
		const projectTimer = storedTimers[refProject];

		if (projectTimer) {
			const currentTime = Date.now();
			const endTime = projectTimer;
			const remainingTime = endTime - currentTime;
			if (remainingTime > 0) {
				return Math.floor(remainingTime / 1000);
			} else {
				delete storedTimers[refProject];
				localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
				return 0;
			}
		}
		return 0;
	}, [getStoredTimers, refProject]);

	const startTimer = useCallback(
		(voteTimer) => {
			const endTime = Date.now() + voteTimer * 1000;
			const storedTimers = getStoredTimers();
			storedTimers[refProject] = endTime;
			localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
			setVoteTime(voteTimer);
			setIsVoteReady(false);
		},
		[getStoredTimers, refProject]
	);

	useEffect(() => {
		const remainingTime = getRemainingTime();
		setVoteTime(remainingTime);

		if (remainingTime > 0) {
			setIsVoteReady(false);
			const interval = setInterval(() => {
				setVoteTime((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						const storedTimers = getStoredTimers();
						delete storedTimers[refProject];
						localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
						setIsVoteReady(true);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);

			return () => clearInterval(interval);
		} else {
			setIsVoteReady(true);
		}
	}, [getRemainingTime, getStoredTimers, refProject]);

	useEffect(() => {
		if (voteTime > 0 && !isVoteReady) {
			const interval = setInterval(() => {
				setVoteTime((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						const storedTimers = getStoredTimers();
						delete storedTimers[refProject];
						localStorage.setItem(TIMER_STORAGE_KEY, JSON.stringify(storedTimers));
						setIsVoteReady(true);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [voteTime, isVoteReady, getStoredTimers, refProject]);

	const theShitHandler = async (project) => {
		if (isVoteBlock) return;
		if (!isVoteReady) {
			addNotification(
				'error',
				`You can't vote right now. Please wait ${Math.floor(voteTime / 60)} minutes.`
			);
			return;
		}

		if (!user?.wallet_balance || user?.wallet_balance <= 0) {
			addNotification(
				'error',
				'Your $hit balance is empty. Please refill balance to vote.'
			);
			return;
		}

		setVoteLoading(true);
		try {
			const res = await vote({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				project_id: refProject,
			}).unwrap();
			setTotalVotes(res.votes_total);
			addNotification('success', 'Your vote counted!');
			startTimer(res.vote_timer);
			setVoteLoading(false);
			if (res.success) {
				const updatedUser = {
					...user,
					wallet_balance: res.user_balance,
				};
				setUser(updatedUser);
			}
		} catch (e) {
			addNotification('error', 'Your vote was not counted. Please try again.');
			setVoteLoading(false);
		}
	};

	const handleMakeClick = (projectId) => {
		if (project?.active_tasks.length > 0) {
			showPopup(<ProjectTasks projectId={projectId} />);
		} else {
			addNotification('info', 'There are no tasks available');
		}
	};

	const handleCopyToClipboard = () => {
		navigator.clipboard
			.writeText(project.contract)
			.then(() => {
				addNotification('info', 'Contract copied!');
			})
			.catch((err) => {
				addNotification('error', 'Failed to copy');
			});
	};

	const formatTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = timeInSeconds % 60;
		return `${minutes}:${String(seconds).padStart(2, '0')}`;
	};

	const secondsFormatTime = (timeInSeconds) => {
		const seconds = timeInSeconds % 60;
		return `${String(seconds)}`;
	};

	const openLink = (link) => {
		window.open(link, '_blank');
	};

	const checkTask = async () => {
		try {
			const res = await checkTasks({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				projects_task_id: project.tasks[0].taskId,
			}).unwrap();

			if (res) {
				const updatedUser = {
					...user,
					projects_tasks: res.projects_tasks,
					wallet_balance: res.wallet_balance,
				};
				setUser(updatedUser);
				addNotification('success', 'Task completed successfully!');
			} else {
				addNotification('error', 'Task check failed. Please try again.');
			}
		} catch (e) {
			addNotification('info', 'Task is already competed');
		}
	};

	if (isLoading) {
		return <Preloader />;
	}

	const navBack = () => {
		navigate(`/activity/`);
	};

	const inviteTG = () => {
		const shareUrl = getTelegramShareLink(user.id_telegram, refProject);
		window.open(shareUrl, '_blank');
	};

	return (
		<>
			<div className='projects'>
				<div className='projects__body'>
					<div className='projects__slider'>
						<div className='swiper'>
							<div className='swiper-wrapper'>
								<div className='swiper-slide'>
									<div className='projects__slide'>
										<div className='projects__card'>
											<MyButton
												variant='smallIcon'
												style={{ top: '20px', left: '20px' }}
												onClick={() => navBack()}
											>
												<Icons.Larrow />
											</MyButton>
											<MyButton
												variant='smallIcon'
												style={{ top: '20px', right: '20px' }}
												onClick={inviteTG}
											>
												<Icons.Invite />
											</MyButton>

											{/* {project.has_game === 1 && ( */}
											<div className='projects__eventVotes'>
												<div className='projects__eventVotes-left'>
													<span className='projects__eventVotes-text'>Users Voted</span>
													<span className='projects__eventVotes-number'>
														<Icons.User />
														{project.total_voters[0].total_voters}
													</span>
												</div>
												<div className='projects__eventVotes-right'>
													<span className='projects__eventVotes-text'>Total Votes</span>
													<span className='projects__eventVotes-number'>
														{project.vote_total}
														<Icons.Shit />
													</span>
												</div>
											</div>
											{/* )} */}
											{/* {project.has_game !== 1 && ( */}
											{/* <MyBalance>
												<div>
													{project.vote_total}
													<Icons.Shit />
												</div>
											</MyBalance> */}
											{/* )} */}
											{/* {project.has_game !== 1 && (
												<div className='projects__rank'>rank: {project.rank}</div>
											)} */}
											<div
												className='projects__image'
												// onClick={handleImageClick}
											>
												{/* {project.has_game === 1 && <Icons.highlight />} */}
												<picture>
													<img src={`${imageBaseUrl}${project.image}`} alt='Image' />
												</picture>
											</div>
											<div className='projects__name'>{project.name}</div>
											{project.tokenName !== null ? (
												<div className='projects__extra'>{project.tokenName}</div>
											) : null}
											{project.contract !== null ? (
												<div
													className='projects__contract'
													onClick={handleCopyToClipboard}
													style={{ cursor: 'pointer' }}
												>
													contract:{' '}
													<span className='projects__contract_name'>
														{' '}
														{project.contract}
													</span>
													<Icons.Copy />
												</div>
											) : null}
											<div className='projects__buttons'>
												<MyButton
													onClick={() => theShitHandler(project)}
													variant={!isVoteReady ? 'inactive' : 'default'}
													className={`mainBtn`}
													disabled={!isVoteReady || isVoteLoading}
												>
													{isVoteBlock && isVoteReady ? (
														<>Vote in {secondsFormatTime(blockTime)}</>
													) : isVoteReady ? (
														<>the $hit!</>
													) : (
														<>cool down: {formatTime(voteTime)}</>
													)}
												</MyButton>
												{project.active_tasks.length >= 2 ? (
													<MyButton
														onClick={() => handleMakeClick(project?.id)}
														variant='black'
														className='mainBtn'
													>
														tasks{' '}
														<span style={{ color: 'black' }}>
															{project.active_tasks.length}
														</span>
													</MyButton>
												) : (
													<div>
														{project.active_tasks.length === 1 ? (
															project.active_tasks.map((task) => {
																const isTaskCompleted = user.projects_tasks?.some(
																	(userTask) => userTask.projects_task_id === task.taskId
																);

																const isDisabled = disabledButtons[task.taskId];

																return (
																	<MyButton
																		key={task.taskId}
																		onClick={() =>
																			handleButtonClick(
																				task.taskId,
																				task.link,
																				isTaskCompleted
																			)
																		}
																		className={`mainBtn`}
																		variant={isDisabled ? 'inactive' : 'black'}
																		disabled={isDisabled}
																	>
																		{task.name}
																	</MyButton>
																);
															})
														) : (
															<p style={{ textAlign: 'center' }}>
																No tasks available for this project.
															</p>
														)}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<NavigationWidget />
			</div>
		</>
	);
};

export default ProjectRef;
