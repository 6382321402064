import React, { useEffect } from 'react';
import shitWin from '../../../../../../assets/img/shitWin.webp';
import shitLose from '../../../../../../assets/img/shitLose.webp';
import Icons from '../../../../../Common/IconsComponent';
import { usePopup } from '../../../../../Popups/PopupContext';
import { MyButton } from '../../../../../Common/styles';
import { useEventNotifyMutation } from '../../../../../../services/phpService';

const EventWinner = ({ winner, eventId }) => {
	const { hidePopup } = usePopup();
	const [eventNotify] = useEventNotifyMutation();

	useEffect(() => {
		const sendEventNotification = async () => {
			try {
				await eventNotify({
					account_event_gaming_id: eventId,
				});
			} catch (error) {
				console.error('Failed to update winner notify status:', error);
			}
		};

		sendEventNotification();
	}, [eventId, eventNotify]);

	const closeModal = () => {
		hidePopup();
	};

	const handleOverlayClick = (e) => {
		if (e.target.classList.contains('overlay')) {
			closeModal();
		}
	};

	return (
		<div
			className='overlay'
			onClick={handleOverlayClick}
			style={{
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				width: '100%',
				zIndex: 1000,
			}}
		>
			<div
				style={{
					height: '100%',
					background: winner ? '#fff500' : '#ffffff',
					borderRadius: '32px 32px 0 0',
					width: '100%',
					padding: '18px',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<div
						style={{ cursor: 'pointer', display: 'flex', alignSelf: 'flex-end' }}
						onClick={closeModal}
					>
						<Icons.Close style={{ path: { fill: 'var(--black)' } }} />
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							height: '132px',
							width: '132px',
						}}
					>
						<img
							src={winner ? shitWin : shitLose}
							alt={winner ? 'winner logo' : 'try again logo'}
							style={{ height: '100%', width: '100%' }}
						/>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								fontWeight: '400',
								fontSize: '32px',
								textAlign: 'center',
							}}
						>
							<h4>{winner ? 'You did it!' : 'Not this time!'}</h4>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								fontWeight: '400',
								fontSize: '16px',
								lineHeight: '150%',
								textAlign: 'center',
								width: '300px',
								marginTop: '6px',
								fontFamily: 'Kumbh',
							}}
						>
							<h4>
								{winner
									? 'Congratulations on your victory in this epic showdown!'
									: 'Maybe you’ll get lucky next time! Keep pushing forward!'}
							</h4>
						</div>
					</div>
					<MyButton
						onClick={closeModal}
						className='mainBtn'
						style={{ marginTop: '26px' }}
					>
						OK
					</MyButton>
				</div>
			</div>
		</div>
	);
};

export default EventWinner;
