import React, { useState, useEffect } from 'react';
import { usePopup } from '../../../../../../Popups/PopupContext.js';
import { usePassEventMutation } from '../../../../../../../services/phpService';
import Icons from '../../../../../../Common/IconsComponent.jsx';
import { MyButton } from '../../../../../../Common/styles';
import '../StartEvent.scss';

const StartEventScreenModal = ({
	id_telegram,
	gamingId,
	payValueShit,
	payValueStars,
	walletBalance,
	eventStatus,
	eventId,
	navigate,
}) => {
	const { hidePopup, user } = usePopup();
	const [passEvent] = usePassEventMutation();
	const [currentStep, setCurrentStep] = useState('select');
	const [paymentType, setPaymentType] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleSelectPayment = (type) => {
		setPaymentType(type);
		setCurrentStep('confirm');
	};

	const handlePayment = async () => {
		setErrorMessage(null);
		try {
			const response = await passEvent({
				id_telegram: id_telegram,
				event_gaming_id: gamingId,
				payment_type: paymentType,
			});

			if (paymentType === 2) {
				if (response && response.data.data.invoice_response.result) {
					setErrorMessage(null);
					const resultUrl = response.data.data.invoice_response.result;
					window.location.href = resultUrl;
				} else {
					setErrorMessage('Failed to join event with stars.');
				}
			} else if (paymentType === 1) {
				if (response) {
					setErrorMessage(null);
				} else {
					setErrorMessage('Failed to join event with points.');
				}
			}
		} catch (error) {
			setErrorMessage('Failed to join event.');
			console.error('Error joining event:', error);
		}
	};

	const closeModal = () => {
		hidePopup();
	};

	useEffect(() => {
		if (user?.events_gaming?.some((event) => event.event_gaming_id === gamingId)) {
			setCurrentStep('congratulations');
		}
	}, [user?.events_gaming, gamingId]);

	const handleContinue = () => {
		if (eventStatus === 0) {
			navigate(`/events/${eventId}`);
		} else if (eventStatus === 1) {
			navigate(`/events/${gamingId}/game`);
		}
		hidePopup();
	};

	return (
		<div className='startEventModal'>
			{currentStep === 'select' && (
				<>
					<div className='modal__close' onClick={closeModal}>
						<Icons.Close />
					</div>
					<div className='startEventModal__icon'>
						<Icons.Rocket />
					</div>
					<div className='startEventModal__text'>
						<span>SELECT PAYMENT METHOD</span>
					</div>
					<div className='startEventModal__btn-group' style={{ width: '265px' }}>
						<MyButton variant='yellow' onClick={() => handleSelectPayment(2)}>
							pay tg stars
						</MyButton>
						<MyButton onClick={() => handleSelectPayment(1)}>pay points</MyButton>
					</div>
				</>
			)}

			{currentStep === 'confirm' && (
				<>
					<div className='modal__close' onClick={closeModal}>
						<Icons.Close />
					</div>
					<div className='startEventModal__text'>
						<span>{paymentType === 1 ? 'POINTS to be paid' : 'STARS to be paid'}</span>
					</div>
					<div className='startEventModal__price'>
						<span>+ {paymentType === 1 ? payValueShit : payValueStars}</span>
						{paymentType === 1 ? <Icons.Shit /> : <Icons.Rocket />}
					</div>
					{paymentType === 1 && (
						<div className='startEventModal__balance'>
							<span>Your total $hit balance:</span>&nbsp;{walletBalance}&nbsp;
							<Icons.Shit />
						</div>
					)}
					{errorMessage && <div className='error-message'>{errorMessage}</div>}
					<div className='startEventModal__btn-group' style={{ flexDirection: 'row' }}>
						<MyButton onClick={() => setCurrentStep('select')}>Cancel</MyButton>
						<MyButton
							variant={
								paymentType === 1 && walletBalance < payValueShit
									? 'inactiveShadow'
									: 'black'
							}
							onClick={handlePayment}
							disabled={paymentType === 1 && walletBalance < payValueShit}
						>
							Pay
						</MyButton>
					</div>
				</>
			)}

			{currentStep === 'congratulations' && (
				<>
					<div className='startEventModal__icon'>
						<Icons.Star />
					</div>
					<div className='startEventModal__text'>
						<span>Congratulations!</span>
						<p>The payment was successful</p>
						<MyButton
							style={{ marginTop: '30px', width: '100%' }}
							onClick={handleContinue}
						>
							continue
						</MyButton>
					</div>
				</>
			)}
		</div>
	);
};

export default StartEventScreenModal;
